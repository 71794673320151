<template>
  <div style="margin-top: 20px;">
    <!-- <el-button type="primary" style="margin-bottom: 20px;" size="small" @click="$router.back()"
            >返 回</el-button
          > -->
  <el-card>
    <form-create v-model="form" ref="form" @submit.native.prevent :components="[
              {name:'form-item',props:{prop:'question_ids',label:'选择题目', type:'data',showKey:'name',multiple:true,width:'660px',
                dialog:{
                  width:'60%',
                  url:'/xapi/question.question/list'
                  // url:'/xapi/question.question_other/list'
                },
                whereColumns:[
                  {name:'form-item',props:{prop:'keyword',label:'关键字',placeholder:'请输入题目名称、题号',style:{width:'550px'},
                }},
                ],
                columns:[
                  {type:'index',label:'序号',width:'60' , align:'center'},
                  {prop:'id',label:'题号',width:'120' , align:'center'},
                  {prop:'title',label:'题目名称',minWidth:'320px' , align:'center',template:({row})=>{return $jquery('<span>'+row.title+'</span>').text();}},
                  {prop:'grade',label:'题目分数',width:'140' , align:'center'},
                  {prop:'type_str',label:'题目类型',width:'160' , align:'center'},
                  {prop:'create_date',label:'添加时间',width:'160' , align:'center'},
                ],
              }
            }
          ]" inline :labelWidth="'90px'">
          
      <el-form-item class="operate" slot="after">
        <!-- <el-button size="medium" :disabled="(priexRoute && !$isPowers(priexRoute+'Create')) || (form.question_ids||[]).length===0" type="primary" @click="handleCommand('add')">添加</el-button>
        <el-button size="medium" :disabled="(priexRoute && !$isPowers(priexRoute+'Create')) || (form.question_ids||[]).length===0" type="primary" @click="$refs.form.reset()">重置</el-button> -->
        <el-button size="medium" :disabled="(priexRoute && !$isPowers(priexRoute+'Create')) || (form.question_ids||[]).length===0" type="primary" @click="handleCommand('add')">添加</el-button>
        <el-button size="medium" :disabled="(priexRoute && !$isPowers(priexRoute+'Create')) || (form.question_ids||[]).length===0" type="primary" @click="$refs.form.reset()">重置</el-button>
      </el-form-item>
    </form-create>
    <com-table
        v-loading="loading"
        ref="table"
        :data="{
              url:'/xapi/question.question_'+type+'/list_choose_question',
              params:{...$route.params,...$route.query}
            }"
        :columns="columns"
        :where="where"
        :total.sync="total"
        :page-size="20"
        :edit-method="handleTableUpdate"
    >
      <template slot="action" slot-scope="scope">
        <el-button :disabled="priexRoute && !$isPowers(priexRoute+'Edit')" @click="$handleConfirm('你确定移除此道题目吗？','/xapi/question.'+otherType+'/list_choose_del',{question_ids:scope.row.id,...$route.params} , getList)" type="text" size="small">移除</el-button>
      </template>
      <template slot="grade" slot-scope="scope" v-if="scope.row.type==2&&scope.row.is_select==0">
        <i @click="openDialog(scope.row)" class="el-icon-edit" style="margin-right: 5px;cursor: pointer;"></i>{{ scope.row.grade }}
      </template>
    </com-table>
    <!-- <div class="pagination">
              <el-pagination background layout="total,sizes,prev, pager, next" :total="total" @size-change="handleSizeChange" @current-change="currentChange" :page-size="page_size" :current-page="page">
              </el-pagination>
            </div> -->
  </el-card>
  <el-dialog :title="qustionTitle" width="30%" :visible.sync="dialogTableVisible">
      <el-table :data="gridData">
        <el-table-column
          property="answer"
          label="答案"
          align="center"
        ></el-table-column>
        <el-table-column label="分数" align="center">
          <template slot-scope="scope">
              <el-input
                v-model="scope.row.grade"
                placeholder="请输入修改分数"
                @change="
                changeGrade(scope.$index, scope.row.grade)
              "
                style="width: 100%"
                class="inputClass"
              ></el-input
            ></template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
</div>

</template>

<script>
export default {
  props:{
    type:{
      type:String,
      default(){return 'question';}
    },
    otherType:{
      type:String,
      default(){return 'question';}
    },
    priexRoute:null
  },
  data(){
    return {
      loading:false,
      tableData:[],
      page_size:10,
      page:1,
      total:0,
      form:{},
      where:{},
      columns:[
          //:filters="[{text: '2016-05-01', value: '2016-05-01'}, {text: '2016-05-02', value: '2016-05-02'}, {text: '2016-05-03', value: '2016-05-03'}, {text: '2016-05-04', value: '2016-05-04'}]"
        {type:'index',label:'序号',width:'80' , align:'center'},
        {prop:'id',label:'题号',width:'80' , align:'center'},
        {prop:'title',label:'题目名称',minWidth:'320px' , align:'center',template:({row})=>{return this.$jquery('<span>'+row.title+'</span>').text();}},
        {prop:'grade',label:'题目分数',width:'140',  slot:'grade' ,align:'center',isEdit:true},
        {prop:'type_str',label:'题目类型',width:'180' , align:'center'},
        // {prop:'sort',label:'排序',width:'120' , align:'center',isEdit:true,sortable:true},
        {prop:'create_date',label:'添加时间',width:'160' , align:'center'},
        {fixed:'right',label:'操作',width:'80' , slot:'action' , align:'center'},
      ],
      dialogTableVisible:false,
      gridData:[],//填空题数据
      qustionTitle:"",
      list_id:0,//题单id
      question_id:0//题目id

    }
  },
  created(){
    console.log(this.$route)
    this.list_id=this.$route.query.list_id || this.$route.query.other_id
  },
  computed:{
    // tableData(){
    //   return {
    //     url:'/xapi/question.'+this.type+'/list_choose_question'
    //   };
    // },
  },
  methods:{
    routeUpdate(){
      this.getList();
      // this.getListData()
    },
    // 填空题修改分数
    openDialog(row){
      console.log(row,"打开的填空题")
      this.qustionTitle=row.title.replace(/<[^>]+>/g, '')
      this.question_id=row.id

      this.gridData=row.original
      this.dialogTableVisible=true
    },
    // 
    changeGrade(index, id, e) {
      console.log(index, "index");
      console.log(e, "分数");
      let a = this.gridData[index];
      console.log(a, "aaaaaaaaaaa");
    },
     // 修改分数确定
     submit() {
      const newData = this.gridData.map((v) => {
        return {
          answer: v.answer,
          grade: v.grade,
          ttype: v.ttype,
        };
      });
      let data = {
        list_id:this.list_id,
        question_id:this.question_id,
        refer: newData,
      };
      console.log(data, "dataaaaaa");
      this.$http
        .post('/xapi/question.question_'+this.type+'/list_choole_refer_grade', data)
        .then((res) => {
          console.log(res, "修改分数");
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.dialogTableVisible = false;
            this.getList();
          }
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    getListData(){
              this.loading = true;
      console.log(this.type,"------------this.typethis.typethis.type")
      this.$http.post('/xapi/question.question_'+this.type+'/list_choose_question' , {...this.$route.params,page: this.page,page_size: this.page_size}).then(({msg,data})=>{
      // this.$http.post('/xapi/question.question_list/list_choose_question' , {...this.$route.params}).then(({msg,data})=>{
              // this.$refs.form.reset();
              // this.$handleMessage('' , 'success',()=>{
              //   this.successCallback(data)
              // });
              console.log(data,"dataaaa");
              this.tableData=data.data
              this.total=data.data.total
            }).finally(()=>{
              this.loading = false;
            });
    },
    successCallback(data = {}){
      let {success} = this.$attrs;
      if( typeof success === 'function'){
        success(...arguments)
      }else{
        this.getList();
      }
    },
    // getTopicList(){
    //   this.$http.post('/xapi/question.question/list' , {...this.$route.params,page: this.page,page_size: this.page_size}).then(({msg,data})=>{
    //           console.log(data,"dataaaa");
    //           this.tableData=data.data
    //           this.total=data.data.total
    //         }).finally(()=>{
    //           this.loading = false;
    //         });
    // },
    getList(where){
      // console.log(where,"whereeeeeeeeeeeeeeeeee")
      where = where || this.where;
      if(this.$refs.table){
        this.$refs.table.reset(where)
      }
    },
    handleCommand(command , ...vars){
      if(command === 'add'){
        this.$refs.form.validate((_form,valid , errors)=>{
          if(valid){
            this.loading = true;
            this.$http.post('/xapi/question.'+this.otherType+'/list_choose_save' , {...this.form,...this.$route.params}).then(({msg,data})=>{
              this.$refs.form.reset();
              this.$handleMessage(msg , 'success',()=>{
                this.successCallback(data)
                this.getListData()
              });
            }).finally(()=>{
              this.loading = false;
            });
          }else if(errors.length > 0){
            this.$handleMessage(errors[0].message , 'error');
          }
        })
      }
    },
    handleTableUpdate(value , oldValue , {row , column , $props}){
      console.log('修改分数')
      this.$http.request({
        // url : '/xapi/question.'+this.otherType+'/updateChooseField',
        url : '/xapi/question.'+this.otherType+'/setQuestionGrade',
        params:{
          ...this.$route.params,
          field:column.prop,
          question_id:row.id,
          value:value
        }
      }).then((data)=>{
        this.$message.success('修改成功')
        this.getList();

          // this.$refs.table.reset(where)
		    if(column.prop === 'sort')this.successCallback(data);


	  }).catch(()=>{
        row[column.prop] = oldValue;
      })
    },
  }
}
</script>

<style scoped>

</style>