var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"20px"}},[_c('el-card',[_c('form-create',{ref:"form",attrs:{"components":[
              {name:'form-item',props:{prop:'question_ids',label:'选择题目', type:'data',showKey:'name',multiple:true,width:'660px',
                dialog:{
                  width:'60%',
                  url:'/xapi/question.question/list'
                  // url:'/xapi/question.question_other/list'
                },
                whereColumns:[
                  {name:'form-item',props:{prop:'keyword',label:'关键字',placeholder:'请输入题目名称、题号',style:{width:'550px'},
                }},
                ],
                columns:[
                  {type:'index',label:'序号',width:'60' , align:'center'},
                  {prop:'id',label:'题号',width:'120' , align:'center'},
                  {prop:'title',label:'题目名称',minWidth:'320px' , align:'center',template:({row})=>{return _vm.$jquery('<span>'+row.title+'</span>').text();}},
                  {prop:'grade',label:'题目分数',width:'140' , align:'center'},
                  {prop:'type_str',label:'题目类型',width:'160' , align:'center'},
                  {prop:'create_date',label:'添加时间',width:'160' , align:'center'},
                ],
              }
            }
          ],"inline":"","labelWidth":'90px'},nativeOn:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('el-form-item',{staticClass:"operate",attrs:{"slot":"after"},slot:"after"},[_c('el-button',{attrs:{"size":"medium","disabled":(_vm.priexRoute && !_vm.$isPowers(_vm.priexRoute+'Create')) || (_vm.form.question_ids||[]).length===0,"type":"primary"},on:{"click":function($event){return _vm.handleCommand('add')}}},[_vm._v("添加")]),_c('el-button',{attrs:{"size":"medium","disabled":(_vm.priexRoute && !_vm.$isPowers(_vm.priexRoute+'Create')) || (_vm.form.question_ids||[]).length===0,"type":"primary"},on:{"click":function($event){return _vm.$refs.form.reset()}}},[_vm._v("重置")])],1)],1),_c('com-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"table",attrs:{"data":{
              url:'/xapi/question.question_'+_vm.type+'/list_choose_question',
              params:{..._vm.$route.params,..._vm.$route.query}
            },"columns":_vm.columns,"where":_vm.where,"total":_vm.total,"page-size":20,"edit-method":_vm.handleTableUpdate},on:{"update:total":function($event){_vm.total=$event}},scopedSlots:_vm._u([{key:"action",fn:function(scope){return [_c('el-button',{attrs:{"disabled":_vm.priexRoute && !_vm.$isPowers(_vm.priexRoute+'Edit'),"type":"text","size":"small"},on:{"click":function($event){return _vm.$handleConfirm('你确定移除此道题目吗？','/xapi/question.'+_vm.otherType+'/list_choose_del',{question_ids:scope.row.id,..._vm.$route.params} , _vm.getList)}}},[_vm._v("移除")])]}},{key:"grade",fn:function(scope){return (scope.row.type==2&&scope.row.is_select==0)?[_c('i',{staticClass:"el-icon-edit",staticStyle:{"margin-right":"5px","cursor":"pointer"},on:{"click":function($event){return _vm.openDialog(scope.row)}}}),_vm._v(_vm._s(scope.row.grade)+" ")]:undefined}}],null,true)})],1),_c('el-dialog',{attrs:{"title":_vm.qustionTitle,"width":"30%","visible":_vm.dialogTableVisible},on:{"update:visible":function($event){_vm.dialogTableVisible=$event}}},[_c('el-table',{attrs:{"data":_vm.gridData}},[_c('el-table-column',{attrs:{"property":"answer","label":"答案","align":"center"}}),_c('el-table-column',{attrs:{"label":"分数","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{staticClass:"inputClass",staticStyle:{"width":"100%"},attrs:{"placeholder":"请输入修改分数"},on:{"change":function($event){return _vm.changeGrade(scope.$index, scope.row.grade)}},model:{value:(scope.row.grade),callback:function ($$v) {_vm.$set(scope.row, "grade", $$v)},expression:"scope.row.grade"}})]}}])})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogTableVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }