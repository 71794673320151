<template>
    <!-- <choose-list :type="'quest_list'" :priex-route="priexRoute"></choose-list> -->
    <choose-list :type="'list'" :otherType="'quest_list'" :priex-route="priexRoute"></choose-list>
</template>

<script>
import chooseList from "@/views/api/teaching/question/chooseList";
export default {
  props:['priexRoute'],
  components:{chooseList}
}
</script>

<style scoped>

</style>